$emojis-requiring-inversion: 'back' 'copyright' 'curly_loop' 'currency_exchange'
  'end' 'heavy_check_mark' 'heavy_division_sign' 'heavy_dollar_sign'
  'heavy_minus_sign' 'heavy_multiplication_x' 'heavy_plus_sign' 'on'
  'registered' 'soon' 'spider' 'telephone_receiver' 'tm' 'top' 'wavy_dash' !default;

%emoji-color-inversion {
  filter: invert(1);
}

.emojione {
  @each $emoji in $emojis-requiring-inversion {
    &[title=':#{$emoji}:'] {
      @extend %emoji-color-inversion;
    }
  }
}
