.activity-stream {
  box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;

  &--under-tabs {
    border-radius: 0 0 4px 4px;
  }

  @media screen and (max-width: $no-gap-breakpoint) {
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &--headless {
    border-radius: 0;
    margin: 0;
    box-shadow: none;

    .detailed-status,
    .status {
      border-radius: 0 !important;
    }
  }

  div[data-component] {
    width: 100%;
  }

  .entry {
    background: $ui-base-color;

    .detailed-status,
    .status,
    .load-more {
      animation: none;
    }

    &:last-child {
      .detailed-status,
      .status,
      .load-more {
        border-bottom: 0;
        border-radius: 0 0 4px 4px;
      }
    }

    &:first-child {
      .detailed-status,
      .status,
      .load-more {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        .detailed-status,
        .status,
        .load-more {
          border-radius: 4px;
        }
      }
    }

    @media screen and (width <= 740px) {
      .detailed-status,
      .status,
      .load-more {
        border-radius: 0 !important;
      }
    }
  }

  &--highlighted .entry {
    background: lighten($ui-base-color, 8%);
  }
}

.button.logo-button svg {
  width: 20px;
  height: auto;
  vertical-align: middle;
  margin-inline-end: 5px;
  fill: $primary-text-color;

  @media screen and (max-width: $no-gap-breakpoint) {
    display: none;
  }
}

.embed {
  .status__content[data-spoiler='folded'] {
    .e-content {
      display: none;
    }

    p:first-child {
      margin-bottom: 0;
    }
  }

  .detailed-status {
    padding: 15px;

    .detailed-status__display-avatar .account__avatar {
      width: 48px;
      height: 48px;
    }
  }

  .status {
    padding: 15px 15px 15px (48px + 15px * 2);
    min-height: 48px + 2px;

    &__avatar {
      inset-inline-start: 15px;
      top: 17px;

      .account__avatar {
        width: 48px;
        height: 48px;
      }
    }

    &__content {
      padding-top: 5px;
    }

    &__prepend {
      margin-inline-start: 48px + 15px * 2;
      padding-top: 15px;
    }

    &__prepend-icon-wrapper {
      inset-inline-start: -32px;
    }

    .media-gallery,
    &__action-bar,
    .video-player {
      margin-top: 10px;
    }

    &__action-bar-button {
      font-size: 18px;
      width: 23.1429px;
      height: 23.1429px;
      line-height: 23.15px;
    }
  }
}
